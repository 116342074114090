import React, { useMemo } from "react"

import { useCore } from "../../hooks/useCore"
import { useData } from "../../hooks/useData"

export const withPodcastListing = Component =>
  React.memo(({ name = "PodcastListing", page, podcasts, ...props }) => {
    const {
      helpers: { sanityContent },
    } = useCore()

    const { data } = useData()

    const title = page?.title
    const content = sanityContent(page?.content)

    const items = podcasts?.edges?.map(({ node }) => node)

    Component.displayName = name
    return useMemo(() => <Component {...props} {...page} title={title} content={content} podcasts={items} data={data} />, [])
  })
