import React from "react"

import { withPodcastListing } from "./withPodcastListing"
import { getFluidGatsbyImage, routeResolver } from "../Wrappers/Helpers"
import { PodcastCard } from "./PodcastCard"
import { Grid } from "../Grid/Grid"
import { Hero } from "../Section/Hero/Hero"
import { Container, GridColumn, HeroLogo, HeroP, HeroButton } from "./PodcastListing.styles"

export const PodcastListing = withPodcastListing(
  ({ data, podcasts }: Props): JSX.Element => (
    <>
      <Hero colour={"primary"} size={"large"} extended>
        <Container width={`md`}>
          <HeroLogo image={data.podcastImage.childImageSharp.gatsbyImageData} />
          <HeroP>
            Honest, candid advice from some of <br />
            Australia's top thinkers and entrepreneurs
          </HeroP>
          <HeroButton
            as={`a`}
            theme={"tertiary"}
            colour={"outline"}
            size={"medium"}
            target={`_blank`}
            href={`//podcasts.apple.com/us/podcast/she-mentors/id1434537979`}
          >
            Subscribe to Podcast
          </HeroButton>
        </Container>
      </Hero>

      {podcasts?.length > 0 && (
        <Container condense={`<md`} spacing={`lg`} width={`xl`}>
          <Grid>
            {podcasts?.map(item => (
              <GridColumn key={item?.id} width={`md:w-1/2 lg:w-1/3`}>
                <PodcastCard
                  image={item?.image && getFluidGatsbyImage(item?.image, { maxWidth: 800, aspectRatio: 1 })}
                  title={item?.title}
                  subtitle={item?.summary}
                  link={routeResolver({ item })}
                  date={item?.attributes?.publishedAt && new Date(item?.attributes?.publishedAt)}
                  id={item?.id}
                />
              </GridColumn>
            ))}
          </Grid>
        </Container>
      )}
    </>
  )
)

export interface Props {
  data?: any
  podcasts?: Array<any>
}
